import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

//const endpoint = 'http://192.168.0.106:8000/api/';
//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
const endpoint = 'https://admin.bdlawservice.com/public/api/';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CasetodayService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getDraftStatus(postData): Observable<any> {

    if(postData.searchDate.getFullYear() == (new Date()).getFullYear()){
        return this.http.post(endpoint + 'is_draft_copy', postData, httpOptions).pipe(
          map(this.extractData));
    }

  }
  postHDcheckduplicate(postData): Observable<any> {
    return this.http 
      .post(endpoint + "savehdnewcaseAddcheck", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCasetoday(postData): Observable<any> {

    //if(postData.searchDate.getFullYear() == 2019){
      return this.http.post(endpoint + 'casetoday', postData, httpOptions).pipe(
        map(this.extractData));
    /*} else if (postData.searchDate.getFullYear() == 2018){
        return this.http.post(endpoint + 'casetoday2018', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2017) {
        return this.http.post(endpoint + 'casetoday2017', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2016) {
        return this.http.post(endpoint + 'casetoday2016', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2015) {
        return this.http.post(endpoint + 'casetoday2015', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2014) {
        return this.http.post(endpoint + 'casetoday2014', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2013) {
        return this.http.post(endpoint + 'casetoday2013', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2012) {
        return this.http.post(endpoint + 'casetoday2012', postData, httpOptions).pipe(
          map(this.extractData));
    } else {
      return;
    }*/
  }
  
  getPreviousResult(postData): Observable<any> {
    
    //if(postData.searchDate.getFullYear() == 2019){
        return this.http.post(endpoint + 'previous_result', postData, httpOptions).pipe(
          map(this.extractData));
    /*} else if(postData.searchDate.getFullYear() == 2018){
        return this.http.post(endpoint + 'previous_result2018', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2017) {
        return this.http.post(endpoint + 'previous_result2017', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2016) {
        return this.http.post(endpoint + 'previous_result2016', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2015) {
        return this.http.post(endpoint + 'previous_result2015', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2014) {
        return this.http.post(endpoint + 'previous_result2014', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2013) {
        return this.http.post(endpoint + 'previous_result2013', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2012) {
        return this.http.post(endpoint + 'previous_result2012', postData, httpOptions).pipe(
          map(this.extractData));                                                  
    } else {
      return;
    }*/
  }

  getPreviousResultDateTime(postData): Observable<any> {
    
    //if(postData.searchDate.getFullYear() == 2019){
        return this.http.post(endpoint + 'prev_result_datetime', postData, httpOptions).pipe(
          map(this.extractData));
    /*} else if (postData.searchDate.getFullYear() == 2018){
        return this.http.post(endpoint + 'prev_result_datetime2018', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2017) {
        return this.http.post(endpoint + 'prev_result_datetime2017', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2016) {
        return this.http.post(endpoint + 'prev_result_datetime2016', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2015) {
        return this.http.post(endpoint + 'prev_result_datetime2015', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2014) {
        return this.http.post(endpoint + 'prev_result_datetime2014', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2013) {
        return this.http.post(endpoint + 'prev_result_datetime2013', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2012) {
        return this.http.post(endpoint + 'prev_result_datetime2012', postData, httpOptions).pipe(
          map(this.extractData));
    } else {
      return;
    }*/
  }

  getPreviousDate(postData): Observable<any> {
    
    //if(postData.searchDate.getFullYear() == 2019){
        return this.http.post(endpoint + 'prev_date', postData, httpOptions).pipe(
          map(this.extractData));
    /*} else if (postData.searchDate.getFullYear() == 2018){
        return this.http.post(endpoint + 'prev_date2018', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2017) {
        return this.http.post(endpoint + 'prev_date2017', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2016) {
        return this.http.post(endpoint + 'prev_date2016', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2015) {
        return this.http.post(endpoint + 'prev_date2015', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2014) {
        return this.http.post(endpoint + 'prev_date2014', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2013) {
        return this.http.post(endpoint + 'prev_date2013', postData, httpOptions).pipe(
          map(this.extractData));
    } else if (postData.searchDate.getFullYear() == 2012) {
        return this.http.post(endpoint + 'prev_date2012', postData, httpOptions).pipe(
          map(this.extractData));                                                  
    } else {
      return;
    }*/
  }

  getTotalcaselisthighcourt(postData): Observable<any> {
    return this.http.post(endpoint + 'totalcaselisthd', postData, httpOptions).pipe(
      map(this.extractData));
  }

  getTotalcaselisthighcourtdetails(postData): Observable<any> {
    return this.http.post(endpoint + 'totalcaselisthddetails', postData, httpOptions).pipe(
      map(this.extractData));
  }

  getTotalcaselisthighcourtcount(postData): Observable<any> {
    return this.http.post(endpoint + 'totalcaselisthdcount', postData, httpOptions).pipe(
      map(this.extractData));
  }

  getTodayTotalcaselisthdcount(postData): Observable<any> {
    return this.http.post(endpoint + 'todaytotalcaselisthdcount', postData, httpOptions).pipe(
      map(this.extractData));
  }


  getTotalcaselistCountTW(postData): Observable<any> {
    return this.http.post(endpoint + 'totalcaselisttwcount', postData, httpOptions).pipe(
      map(this.extractData));
  }

  getTotalcasesummaryLW(postData): Observable<any> {
    return this.http.post(endpoint + 'casesummarydetailslw', postData, httpOptions).pipe(
      map(this.extractData)); 
  }

  getTotalcasesummaryLWSadmin(postData): Observable<any> {
    return this.http.post(endpoint + 'casesummarydetailslw', postData, httpOptions).pipe(
      map(this.extractData)); 
  }

  getUserDetails(postData): Observable<any> {
    return this.http.post(endpoint + 'userdetails', postData, httpOptions).pipe(
      map(this.extractData)); 
  } 


  postSaveHDCase(postData): Observable<any> {
    return this.http.post(endpoint + 'savehdnewcase', postData, httpOptions).pipe(
      map(this.extractData)); 
  } 

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
}


